import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) =>{
    if(screen.fadeInScreen !== props.id)return;
    Animations.animations.fadeInscreen(props.id)

  };
  const fadeInSubscription = 
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    const SCREEN_CONSTANTS = {
      
      description: 
    
      <p>Full stack web and mobile developer with background knowledge of Vue.js and React, along with a knack of building applications with utmost efficiency. Skilled developer with a passion for technology and programming. 
        Strong professional with a BSC willing to be an asset for an organization. skilled developer with a passion for technology and programming, curious about new technologies and like 
        working in a fast paced and agile environment. Sharp thinker and always bring creativity to problem solving. 
        I have endless thirst of knowledge, focus and performance driven.</p>,
      highlights: {
        bullets: [
          "Full Stack web and mobile development",
          "Interactive Front End as per the design",
          "React and React Native",
          "Redux for State Mnanagement",
          "Building REST API",
          "Managing database",
        ],
        heading: "Here are a Few Highlights: ",
        aboutSubHeading: "Fullstack developer",
      }
    }

    const renderHighlight = ()=>{
      return(
        SCREEN_CONSTANTS.highlights.bullets.map((value, i)=>(
          <div className="highlight" key={i}>
              <div className="highlights-blob"></div>
              <span>{value}</span>

          </div>
        ))
      )
    }
  return(
    <div className="about-me-container screen-container fade-in" id={props.id || ""}>
      <div className="about-me-parent">
        <ScreenHeading title={"ABout Me"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
          <div className="highlight-heading">
                <span>{SCREEN_CONSTANTS.highlights.aboutSubHeading}</span>
              </div>
            <span className="about-me-description">{SCREEN_CONSTANTS.description} </span>
            
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-me-option">
            <button className="btn primary-btn"
                    onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
                     > Hire Me </button>
                    <a href='PhamelaHoffmannCV.pdf' download='PhamelaHoffmannCV.pdf'>
                        <button className='btn highlighted-btn'>Get Resume</button>
                    </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
