import React, { useState, useEffect } from 'react'
import Animations from '../../utilities/Animations';
import ScrollService from '../../utilities/ScrollService';
import ScreenHeading from '../../utilities/ScreenHeading/ScreenHeading';
import "./Resume.css";

export default function Resume(props){
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) =>{
    if(screen.fadeInScreen !== props.id)return;
    Animations.animations.fadeInscreen(props.id);
  };

  const fadeInSubscription = 
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);


  const ResumeHeading = (props) => {
  return(
    <div className="resume-heading">
    <div className="resume-main-heading">
      <div className="heading-bullet"></div>
        <span>{props.heading ? props.heading : ""}</span>
        {props.fromDate && props.toDate ? (
          <div className="heading-date">
            {props.fromDate + "_" + props.toDate}
          </div>
        ) : (
          <div></div>

        )}
      </div>
      <div className='resume-sub-heading'>
        <span>{props.subHeading ? props.subHeading : ''}</span>
      </div>
      <div className='resume-heading-description'>
        <span>{props.description ? props.description : '' }</span>

      
    </div>
  </div>
  );
   
};

  /*Static Resume data for teh labels */
  const resumeBullets = [
    { label: "Education", logoSrc: "education.svg"},
    { label: "Work History", logoSrc: "work-history.svg"},
    { label: "Programming Skills", logoSrc: "programming-skills.svg"},
    { label: "Projects", logoSrc: "projects.svg"},
    { label: "Interests", logoSrc: "interests.svg"},

];
// here we have 
const programmingSkillsDetails = [
    {skill: "Javascript", ratingPercentage: 65},
    {skill: "Vue JS", ratingPercentage: 55},
    {skill: "React JS", ratingPercentage: 55},
    {skill: "SQL/SQL Lite", ratingPercentage: 65},
    {skill: "Vue JS", ratingPercentage: 55},
    {skill: "Node JS", ratingPercentage: 50},
    {skill: "CSS", ratingPercentage: 85},
    {skill: "HTML", ratingPercentage: 85},
    {skill: "C#", ratingPercentage: 60},
    {skill: "Python", ratingPercentage: 70},
    {skill: "Typescript", ratingPercentage: 55},
    {skill: "Bootstrap", ratingPercentage: 85},
    {skill: "PHP", ratingPercentage: 60},
    {skill: "Agile", ratingPercentage: 85},
];
<br />

  const projectsDetails = [
    {
      title: "Personal Portfolio Website",
      duration: {fromDate: "2023", toDate: "2023"},
      description: "A Personal Portfolio website to showcase all my details and projects and one place.",
      subHeading: "Technologies Used: React JS, Bootstrap",
    },
    {
      title: "Mobile E-shop",
      duration: {fromDate: "2020", toDate: "2021"},
      description: "A Personal Portfolio website to showcase all my details and projects and one place.",
      subHeading: "Technologies Used: React JS, Bootstrap",
    },
    {
      title: "Portfolio Website with Python",
      duration: {fromDate: "2022", toDate: "2022"},
      description: "A Personal Portfolio website to showcase all my details and projects and one place.",
      subHeading: "Technologies Used: React JS, Bootstrap",
    },
    {
      title: "All ABout Documents",
      duration: {fromDate: "2022", toDate: "2022"},
      description: "A Personal Portfolio website to showcase all my details and projects and one place.",
      subHeading: "Technologies Used: React JS, Bootstrap",
    },
  
  ];

  const resumeDetails = [
    <div className='resume-screen-container' key="education">
      <ResumeHeading
      heading={"Erhvervsakademi Sjælland, Roskilde Denmark"}
      subHeading={"BACHELOR IN WEB DEVELOPMENT"}
      fromDate={"2015"}
      toDate={"2018"}
      
      />
       <ResumeHeading
      heading={"AP Computer Science"}
      subHeading={"Erhvervsakademi Sjælland, Roskilde Denmark"}
      fromDate={"2012"}
      toDate={"2015"}
      
      />
        <ResumeHeading
      heading={"Web Integrator"}
      subHeading={"Roskilde Tekniske Skole, Denmark"}
      fromDate={"2010"}
      toDate={"2012"}
      
      />
          <ResumeHeading
      heading={"Bachelor in Education"}
      subHeading={"West Visayas State University, Iloilo Philippines"}
      fromDate={"1997"}
      toDate={"2001"}
      
      />
    
    </div>,
    
    <div className='resume-screen-container' key="work-experience">
      <div className='experience-container'>
       <ResumeHeading
      heading={"Arp Hansen Hotel Group"}
      subHeading={"Frontend Developer (Web and mobile developer)"}
      fromDate={"2018"}
      toDate={"2022"}
      />
    
      <div className='experience-description'>
        <span className='resume-description-text'>
        
            - Collaborated with 2 backend developer 
        </span>
        <br />
        <span className="resume-description-text">
        - Making sure that all Hotel website is responsive in all devices.
          </span>
          <br />
          <span className="resume-description-text">
        - Testing web pages on the structure and design of 14 websites
          </span>
          <br />
        
      
        </div>
        <ResumeHeading
      heading={"Arp Hansen Hotel Group"}
      subHeading={"Frontend Developer (Web and mobile developer)"}
      fromDate={"2018"}
      toDate={"2022"}
      />
     
      <div className='experience-description'>
        <span className='resume-description-text'>
            - Collaborated with 2 backend developer 
        </span>
        <span className="resume-description-text">
          - Fixing error, functionality, broken links, styling, fonts 
          </span>
          <br />
          <span className="resume-description-text">
          - Designing and optimizing layout for different screen sizes
          </span>
          <br />
          <span className="resume-description-text">
          - Ensuring that the website loads quickly on all devices
          </span>
          <br />
     
        <br />
        </div>
      
      </div>
      
      
    </div>,
    

       /* PROGRAMMING SKILLS */
       <div
       className="resume-screen-container programming-skills-container"
       key="programming-skills"
     >
       {programmingSkillsDetails.map((skill, index) => (
         <div className="skill-parent" key={index}>
           <div className="heading-bullet"></div>
           <span>{skill.skill}</span>
           <div className="skill-percentage">
             <div
               style={{ width: skill.ratingPercentage + "%" }}
               className="active-percentage-bar"
             ></div>
           </div>
         </div>
       ))}
     </div>,
  
     /* PROJECTS */
     <div className="resume-screen-container" key="projects">
       {projectsDetails.map((projectsDetails, index) => (
         <ResumeHeading
           key={index}
           heading={projectsDetails.title}
           subHeading={projectsDetails.subHeading}
           description={projectsDetails.description}
           fromDate={projectsDetails.duration.fromDate}
           toDate={projectsDetails.duration.toDate}
         />
       ))}
     </div>,
  
     /* Interests */
     <div className="resume-screen-container" key="interests">
       <ResumeHeading
         heading="Teaching"
         description="Apart from being a tech enthusiast and a code writer, i also love to teach people what i know simply because i believe in sharing."
       />
       <ResumeHeading
         heading="Music"
         description="Listening to soothing music is something i can never compromise with, skimming through Spotify's pop songs charts is at times the best stress reliever that i can get my hands on."
       />
       <ResumeHeading
         heading="Volunteering"
         description="I like to challenge my reflexes a lot while competing in football games, pushing the rank and having interactive gaming sessions excites me the most."
       />
     </div>,


  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset ={
      style: {transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
  return resumeBullets.map((bullet, index) => (
    <div onClick={()=> handleCarousal(index)}
    className={index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"}
    key= {index}
    >
      <img className='bullet-logo'
      src={require(`../../assets/Resume/${bullet.logoSrc}`)}
      alt = 'oops,,, no internet conection'
      />
      <span className="bullet-label">{bullet.label}</span>
    </div>
  ));
};

const getResumeScreens = () => {
  return(
    <div style={carousalOffsetStyle.style}
    className="resume-details-carousal"
    >
      {resumeDetails.map((resumeDetail) => resumeDetail)}
    </div>

  );
};

useEffect(() => {
  return () => {
    /* UNSUBSCRIBE THE SUBSCRIPTIONS */
    fadeInSubscription.unsubscribe();
  };
}, [fadeInSubscription]);
 
  return (
    <div 
        className="resume-container screen-container fade-in"
        id ={props.id || ""} 
      >
      <div className='resume-content'>
        <ScreenHeading title={"Resume"} subHeading={"My Formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>
          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
        
    </div>
  );
};