import React from "react";
import Typical from 'react-typical'
import ScrollService from "../../../utilities/ScrollService";
import './Profile.css';


export default function Profile() {
    return (
        <div className='profile-container'>
            <div className='profile-parent'>
                <div className='profile-details'>
                    <div className='colz'>
                        <div className='colz-icon'>
                        <a href="https://www.facebook.com/PhamelaDH">
                            <i className='fa fa-facebook-square'></i>
                        </a>
                        <a href='https://www.facebook.com/PhamelaDH'>
                            <i className='fa fa-google-plus-square'></i>
                        </a>
                        <a href='https://www.instagram.com/phamelahoffmann/'>
                            <i className='fa fa-instagram'></i>
                        </a>
                        <a href='https://www.youtube.com/channel/UCrW2eLv9_Lzgq5OK5mTVxKQ'>
                            <i className='fa fa-youtube-square'></i>
                        </a>
                        <a href='https://twitter.com/PDillomis'>
                            <i className='fa fa-twitter'></i>
                        </a>
                        </div>

                    </div>
                
                <div className='profile-details-name'>
                    <span className='primary-text'>
                        {" "}
                        Hello, I'M <span className='highlighted-text'>Phamela</span>
                    </span>
                </div>
                <div className='profile-details-role'>
                    <span className='primary-text'>
                        {" "}
                        <h1>
                            {" "}
                           <Typical
                             loop={Infinity}
                             steps={[
                                "Enthusiastic Dev 😎",
                                1000,
                                "Full stack Developer 💻",
                                1000,
                                "Mern stack Dev 📱",
                                1000,
                                "Agile Software Development 🔴",
                                1000,
                                "React/React Native 🌐",
                                1000,
                              ]}
                           
                            />  
                        </h1>
                        </span>
                        <span className='profile-role-tagline'>
                            Knack of building applications with front and back end operations.
                        </span>
                   
                </div>
                <div className='profile-options'>
                <button className="btn primary-btn" onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
                    > Hire Me </button>
                    <a href='PhamelaCV.pdf' download='Phamela PhamelaCV.pdf'>
                        <button className='btn highlighted-btn'>Get Resume</button>
                    </a>

                </div>
                </div>
                <div className='profile-picture'>
                    <div className='profile-picture-background'></div>
                </div>
            </div>
        </div>
    );
}
