//import Home from './PortfolioContainer/Home/Home';
//import Footer from './PortfolioContainer/Home/Footer/Footer';
//import Profile from "./PortfolioContainer/Home/Profile/Profile"
//import {ToastContainer } from "react-toastify";

import PortfolioContainer from './PortfolioContainer/PortfolioContainer';
import './App.css';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
       {/* <Home /> */}
       <ToastContainer />
       <PortfolioContainer />
    
      
    </div>
  );
}

export default App;
