import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Testimonial.css";
import lady from '../../../src/img/Testimonial/lady.png';
import man from '../../../src/img/Testimonial/man.png';

import mike from '../../../src/img/Testimonial/mike.png';
import shape from '../../../src/img/Testimonial/shape-bg.png';


export default function testimonial (props) {

  let fadeInScreenHandler = (screen) =>{
    if(screen.fadeInScreen !== props.id)return;
    Animations.animations.fadeInscreen(props.id)

  };
  const fadeInSubscription = 
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    const options = {
      loop: true, 
      margin: 0,
      nav: true,
      animateIn: "bounceInRight",
      animateOut: "bounceOutRight",
      dots: true,
      autoplay: true,
      smartSpeed: 1000,
      responsive: {
        0: {
          items:1,
        },
        768: {
          items:1,
        },
        1000: {
          items:3,
        }
      }
    }

  return (
    <div>
      <ScreenHeading
        title={"Testimonial"}
        subHeading={"What My Client Say About Me"}
      />
      <section className="testimonial-section fade-in " id={props.id || ""}>
         <div className="container">
          <div className="row">
            <OwlCarousel className="owl-carousel" id="testimonial-carousel" {...options}>



              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-qoute-left" />
                      Phamela was very helpful and fast to respond to my inquiry to help with Wordpress website issues. 
                      Her knowledge was comprehensive.
                      <i className="fa fa-qoute-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                     <img src={lady} alt="no internet connection"></img>
                     <h5>Janet Dixon</h5>
                     <p>CEO All About Documents</p>
                  </div>
                </div>
              </div>


              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-qoute-left" />
                      Very helpful when building my website, I now have my ecommerce store built from scratch
                      <i className="fa fa-qoute-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                     <img src={mike} alt="no internet connection"></img>
                     <h5>Christian Tusborg</h5>
                     <p>CEO Tusborg Aps</p>
                  </div>
                </div>
              </div>


              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-qoute-left" />
                      Phamela is awesome, delivers exactly what you want and is a great friend as well, 
                      thanks for all your hard work on my website.
                      <i className="fa fa-qoute-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                     <img src={man} alt="no internet connection"></img>
                     <h5>Anders Gamborg</h5>
                     <p>CEO A.GAMBORG APS</p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
         </div>
      </section>
      <div className="footer-image">
        <img src={shape} alt="image not responding" />
      </div>
    </div>
  )
}